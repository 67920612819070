// @flow
import FontFaceObserver from 'fontfaceobserver';
import type {Font} from './types';

export default (fontbook: Array<Font>, timeout: number = 20000): Promise<Array<*>> => Promise.all(
   fontbook.map(f =>
     new FontFaceObserver(f.family, {
       style: f.style,
       weight: f.weight,
     }).load(null, timeout)
   )
 );
