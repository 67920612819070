// @flow
import {FAMILY, SUBSET_FAMILY} from './consts';

export default function set(variable: string) {
  if (document.documentElement) {
    document.documentElement.style.setProperty(
      `--${variable}`,
      `${FAMILY}, ${SUBSET_FAMILY}, 'open-sans', sans-serif`
    );
  }
}
