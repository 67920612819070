// @flow
import type {Font} from './types';
import {FAMILY} from './consts';

const fontbook: Array<Font> = [
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-light.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-light.woff',
        format: 'woff',
      },
    ],
    weight: '300',
    style: 'normal',
  },
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-light-italic.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-light-italic.woff',
        format: 'woff',
      },
    ],
    weight: '300',
    style: 'italic',
  },
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-regular.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-regular.woff',
        format: 'woff',
      },
    ],
    weight: '400',
    style: 'normal',
  },
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-regular-italic.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-regular-italic.woff',
        format: 'woff',
      },
    ],
    weight: '400',
    style: 'italic',
  },
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-medium.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-medium.woff',
        format: 'woff',
      },
    ],
    weight: '500',
    style: 'normal',
  },
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-medium-italic.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-medium-italic.woff',
        format: 'woff',
      },
    ],
    weight: '500',
    style: 'italic',
  },
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-bold.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-bold.woff',
        format: 'woff',
      },
    ],
    weight: '600',
    style: 'normal',
  },
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-bold-italic.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-bold-italic.woff',
        format: 'woff',
      },
    ],
    weight: '600',
    style: 'italic',
  },
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-extrabold.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-extrabold.woff',
        format: 'woff',
      },
    ],
    weight: '800',
    style: 'normal',
  },
  {
    family: FAMILY,
    src: [
      {
        file: 'lomino-extrabold-italic.woff2',
        format: 'woff2',
      },
      {
        file: 'lomino-extrabold-italic.woff',
        format: 'woff',
      },
    ],
    weight: '800',
    style: 'italic',
  },
];

export default fontbook;
