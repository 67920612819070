// @flow
/* eslint  no-console: 0 */
import cookies from 'browser-cookies';

import subset from './subset';
import loadFontbook from './loadFontbook';
import { LOMINO_COOKIE } from './consts';
import set from './set';
import fontCheck from './fontCheck';
import parseFontRequest from './parseFontRequest';

function LominoLoad() {
  const getAttr = (name: string) =>
    document.currentScript && document.currentScript.getAttribute(name);
  const setVars = () => {
    set('fontBase');
    set('fontHeadline');
  };

  const sizes = getAttr('data-sizes') || '';
  const domain = getAttr('data-domain') || undefined;
  const expires = getAttr('data-expires') || 30;
  const switchOnLoad = getAttr('data-switch-on-first') !== 'false';
  const logging = !!getAttr('data-logging');

  try {
    const lominoCookieValue = cookies.get(LOMINO_COOKIE) || '';
    const toLoadFonts = parseFontRequest(sizes);
    const loadingList = fontCheck(toLoadFonts);

    if (!loadingList.length) {
      setVars();
      if (logging) {
        console.log('lomino-loader: All fonts are already loaded');
      }
      return Promise.resolve();
    }

    if (logging) {
      console.log('lomino-loader: Loading following font', loadingList);
    }

    return loadFontbook([subset], 1000)
      .then(() => loadFontbook(loadingList))
      .then(() => {
        if (switchOnLoad) {
          setVars();
        }
        const toAddToCookie = loadingList.reduce(
          (agg, f) => `${agg}${agg ? ',' : ''}${f.weight}:${f.style}`,
          ''
        );
        cookies.set(LOMINO_COOKIE, `${lominoCookieValue}${toAddToCookie}`, {
          domain,
          expires: +expires,
        });
      })
      .catch(err => {
        setVars();
        console.error('lomino-loader: failed to load fonts', err);
        cookies.erase(LOMINO_COOKIE);
      });
  } catch (err) {
    console.error('lomino-loader:unxpected error happened ', err);
    cookies.erase(LOMINO_COOKIE);
  }
}

LominoLoad();
