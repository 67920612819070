// @flow
/* eslint  no-console: 0 */
import cookies from 'browser-cookies';
import { LOMINO_COOKIE } from './consts';
import fontbook from './fontbook';
import parseFontRequest from './parseFontRequest';

export default (toLoadWeights: Array<{ weight: string, style: string }>) => {
  const loadedFonts = parseFontRequest(cookies.get(LOMINO_COOKIE) || '');

  const loadingList = fontbook.reduce((agg, v) => {
    const needsLoading =
        // should load font when no font at all are requested...
      (!toLoadWeights.length ||
        // ... or font requested exists ...
        toLoadWeights.some(w => w.weight === v.weight && w.style === v.style)) &&
        // ... but only when was not loaded already.
      !loadedFonts.some(f => f.weight === v.weight && f.style === v.style);
    if (needsLoading) {
      agg.push(v);
    }
    return agg;
  }, []);

  return loadingList;
};
