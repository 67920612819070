// @flow
import {SUBSET_FAMILY} from './consts';
import type { Font } from './types';

const subset: Font = {
 family: SUBSET_FAMILY,
 src: [
  {
   file: 'lomino-regular-subset.woff2',
   format: 'woff2',
  },
  {
   file: 'lomino-regular-subset.woff',
   format: 'woff',
  },
 ],
 weight: '400',
 style: 'normal',
};

export default subset;
